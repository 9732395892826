var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.activeTab)+" Tickets")])]),_c('div',{staticClass:"card-body"},[_c('ul',{staticClass:"nav nav-tabs nav-justified"},[_c('li',{staticClass:"nav-item"},[_c('span',{class:['nav-link', _vm.activeTab === 'all' && 'active'],on:{"click":() => _vm.handleSetActiveTab('all')}},[_vm._v(" All ")])]),_c('li',{staticClass:"nav-item"},[_c('span',{class:['nav-link', _vm.activeTab === 'Kiosk' && 'active'],on:{"click":() => _vm.handleSetActiveTab('Kiosk')}},[_vm._v(" Kiosks ")])]),_c('li',{staticClass:"nav-item"},[_c('span',{class:['nav-link', _vm.activeTab === 'Router' && 'active'],on:{"click":() => _vm.handleSetActiveTab('Router')}},[_vm._v(" Routers ")])]),_c('li',{staticClass:"nav-item"},[_c('span',{class:['nav-link', _vm.activeTab === 'Other' && 'active'],on:{"click":() => _vm.handleSetActiveTab('Other')}},[_vm._v(" Others ")])])]),(_vm.loading)?_c('div',{staticClass:"py-6"},[_c('table-skeleton')],1):_c('DataTable',{attrs:{"columns":[
          { field: 'id', label: 'Ticket ID', formatFn: _vm.ticketIdFormatter },
          { field: 'subject', label: 'Subject' },
          { field: 'department', label: 'Department' },
          {
            field: 'priority',
            label: 'Priority',
          },
          {
            field: 'user.name',
            label: 'User',
          },
          {
            field: 'is_open',
            label: 'Status',
            formatFn: _vm.statusFormatter,
          },
          { field: 'created', label: 'Added', formatFn: _vm.createdFormatter },
          _vm.loggedInUser.role === _vm.adminRole ||
          _vm.loggedInUser.user_type === _vm.customerSupportUserType
            ? { field: 'view', label: 'Action', sortable: false }
            : null,
        ],"rows":_vm.filteredTickets,"activeTab":_vm.activeTab,"detailsRouteName":"ticket-details"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }