<template>
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <span class="text-capitalize">{{ activeTab }} Tickets</span>
      </div>
      <div class="card-body">
        <ul class="nav nav-tabs nav-justified">
          <li class="nav-item">
            <span
              :class="['nav-link', activeTab === 'all' && 'active']"
              @click="() => handleSetActiveTab('all')"
            >
              All
            </span>
          </li>
          <li class="nav-item">
            <span
              :class="['nav-link', activeTab === 'Kiosk' && 'active']"
              @click="() => handleSetActiveTab('Kiosk')"
            >
              Kiosks
            </span>
          </li>
          <li class="nav-item">
            <span
              :class="['nav-link', activeTab === 'Router' && 'active']"
              @click="() => handleSetActiveTab('Router')"
            >
              Routers
            </span>
          </li>
          <li class="nav-item">
            <span
              :class="['nav-link', activeTab === 'Other' && 'active']"
              @click="() => handleSetActiveTab('Other')"
            >
              Others
            </span>
          </li>
        </ul>

        <div class="py-6" v-if="loading">
          <table-skeleton> </table-skeleton>
        </div>

        <DataTable
          v-else
          :columns="[
            { field: 'id', label: 'Ticket ID', formatFn: ticketIdFormatter },
            { field: 'subject', label: 'Subject' },
            { field: 'department', label: 'Department' },
            {
              field: 'priority',
              label: 'Priority',
            },
            {
              field: 'user.name',
              label: 'User',
            },
            {
              field: 'is_open',
              label: 'Status',
              formatFn: statusFormatter,
            },
            { field: 'created', label: 'Added', formatFn: createdFormatter },
            loggedInUser.role === adminRole ||
            loggedInUser.user_type === customerSupportUserType
              ? { field: 'view', label: 'Action', sortable: false }
              : null,
          ]"
          :rows="filteredTickets"
          :activeTab="activeTab"
          detailsRouteName="ticket-details"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import DataTable from "@/components/DataTable";

export default {
  name: "TicketsTable",
  components: { DataTable },
  data() {
    return {
      activeTab: "all",
      allTickets: [],
      filteredTickets: [],
      filtered: false,
      error: "",
      selectedtickets: null,
      adminRole: "ared_admin",
      customerSupportUserType: "Customer Support Agent",
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
  },
  mounted() {
    this.getTickets();
  },
  methods: {
    getSelectedRows(records) {
      this.selectedtickets = records;
    },
    async getTickets(department) {
      let ticketUrl = `v1/tickets/`;
      if (department) {
        ticketUrl = `v1/tickets/?department=${department}`;
      }
      await axios
        .get(ticketUrl)
        .then((response) => {
          if (response.status === 200) {
            this.loading = false;
            this.allTickets = response.data;
            this.filteredTickets = this.allTickets;
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
    handleSetActiveTab(tab) {
      this.activeTab = tab;
      if (tab === "all") {
        this.filtered = false;
        this.getTickets();
      } else if (tab !== "all") {
        this.filtered = true;
        this.getTickets(this.activeTab);
      }
    },
    createdFormatter(value) {
      return new Date(value).toLocaleString();
    },
    statusFormatter(value) {
      if (value === true) {
        return "Open";
      } else {
        return "Closed";
      }
    },
    ticketIdFormatter(value) {
      return `#${value}`;
    },
  },
};
</script>
