<template>
  <div>
    <div class="row col-12 page-head">
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb page-head-nav">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboard' }">Home</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link-active :to="{ name: 'support-tickets' }">
              Support Tickets
            </router-link-active>
          </li>
        </ol>
      </nav>
    </div>
    <TicketsTable/>
  </div>
</template>
<script>
import TicketsTable from "@/components/Tickets/Table";

export default {
  components: {TicketsTable},
};
</script>
